import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

import Intelligent from './../../assets/images/intelligent.gif';
import richestman from './../../assets/images/richestman.gif';
import richdad from './../../assets/images/richdad.gif';
import booklet from './../../assets/images/booklet.jpg';
import teach from './../../assets/images/teach.gif';
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

const navigate = useNavigate();
const signup = () => {

  navigate("/signup");
}

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'I\'ll Beat Wall Street is a detailed asset tracking system, high torque statistical platform and exclusive news curation service',
    paragraph: 'Benchmark your portfolio performance against the world'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="center-content">
          <Button
            onClick={signup}>
            Join for free
          </Button>
        </div>
      </div>
          </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
